<template>
  <div></div>  
</template>

<script>
import { mapState } from 'vuex'

export default {
  computed: {
    ...mapState('user', {
      msal: state => state.msal
    }),
    api () { return this.msal.api },
    accessToken () { return this.msal.account.accTok },
  },
  methods: {
    async getUserInfo (id) {
      try {
        return (await this.axios({
          method: 'get',
          url: this.api +
            (id
              ? `/users/${id}`
              : '/me'),
          headers: {
            'Content-Type': 'application/json',
            'Authorization': this.accessToken
          }
        })).data
      } catch (e) {
        return null
      }
    },
    async getUserBase64Image (params) {
      let id = this._.get(params, 'id')
      let size = this._.get(params, 'size')
      // allowed size for ms
      if (![48, 64, 96, 120, 240, 360, 432, 504, 648].includes(size)) {
        size = null
      }
      try {
        let { data: img } = await this.axios({
          method: 'get',
          url: this.api + '/' +
            (id ? `users/${id}` : 'me') +
            '/photo' + 
            (size ? `s/${size}x${size}` : '') +
            '/$value',
          headers: {
            'Content-Type': 'image/jpg',
            'Authorization': this.accessToken
          },
          responseType: 'arraybuffer' // important!!!
        })
        let binary = ''
        const unit8Image = new Uint8Array(img)
        for (let i = 0; i < unit8Image.byteLength; i++) {
          binary += String.fromCharCode(unit8Image[i])
        }
        return 'data:image/jpeg;base64,' + window.btoa(binary)
      } catch (e) {
        return null
      }
    },
    async getUserListByInput (val) {      
      try {
        let response = await this.axios({
          method: 'get',
          url:
            this.api +
            '/users' +
            '?$filter=endsWith(mail,\'@nielseniq.com\')' +
            '&$search="displayName:' + val + '" OR "mail:' + val + '"',
          headers: {
            'Content-Type': 'application/json',
            'ConsistencyLevel': 'eventual',
            'Authorization': this.accessToken
          }
        })
        let result = this._.get(response, 'data.value')
        if (!(result instanceof Array)) { result = [] }
        return result
      } catch (e) {
        return []
      }
    },
    async getUserReportChain (id) {      
      try {
        return (await this.axios({
          method: 'get',
          url: this.api +
            (id
              ? `/users/${id}`
              : '/me') +
            '?$expand=manager($levels=max)&$count=true',
          headers: {
            'Content-Type': 'application/json',
            'ConsistencyLevel': 'eventual',
            'Authorization': this.accessToken
          }
        })).data
      } catch (e) {
        return null
      }
    },
    async getUserInTeam (team) {      
      try {
        return (await this.axios({
          method: 'get',
          url: this.api +
            "/users?$filter=department eq '"+ team +"'",
          headers: {
            'Content-Type': 'application/json',
            'Authorization': this.accessToken
          }
        })).data
      } catch (e) {
        return null
      }
    },
    async getItemPreviewUrlByPath (path) {         
      try {
        const itemid = this._.get(await this.axios({
          method: 'get',
          url: this.api + `/users/${this.msal.onedriveId}/drive/root:/${path}:`,
          headers: {
            'Content-Type': 'application/json',
            'Authorization': this.accessToken
          }
        }), 'data.id')
        if (!itemid) { return null }
        return this._.get(await this.axios({
          method: 'post',
          url: this.api + `/users/${this.msal.onedriveId}/drive/items/${itemid}/preview`,
          headers: {
            'Content-Type': 'application/json',
            'Authorization': this.accessToken
          }
        }), 'data.getUrl') || null
      } catch (e) {
        return null
      }
    },
    async getDownloadUrlByPath (path) {         
      try {
        return this._.get(await this.axios({
          method: 'get',
          url: this.api + `/users/${this.msal.onedriveId}/drive/root:/${path}:`,
          headers: {
            'Content-Type': 'application/json',
            'Authorization': this.accessToken
          }
        }), "data['@microsoft.graph.downloadUrl']") || null
      } catch (e) {
        return null
      }
    },
  },
}
</script>

<style>

</style>